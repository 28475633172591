/* HouseDetails.css */
.HouseDetails-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
}

.icon {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
}

.lower-part {
  flex: 1;
}

.detailTitle {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

.info {
  margin-bottom: 10px;
}

.info p {
  margin: 5px 0;
  font-size: 18px;
  color: #555;
}

.error-message {
  color: #ff4d4f;
  font-size: 16px;
  margin-top: 10px;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .HouseDetails-container {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
  }

  .icon {
    width: 100%;
    height: auto;
    margin-right: auto;
  }

  .lower-part {
    width: 100%;
  }

  .detailTitle {
    font-size: 20px;
  }
}
