@import url('https://fonts.googleapis.com/css2?family=Figtree&family=Fjalla+One&family=Pacifico&family=Pinyon+Script&family=Roboto:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Figtree&family=Fjalla+One&family=Pacifico&family=Pinyon+Script&family=Roboto:ital@1&family=Zeyada&display=swap');

.login,
.splash,
.registration {
  display: flex;
  flex-direction: column;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(rgba(47, 47, 47, 0.6), rgba(0, 0, 0, 0.8)), url('https://media.istockphoto.com/id/1456438564/photo/couple-second-key-to-new-home-with-hand-in-heart-symbol.webp?b=1&s=170667a&w=0&k=20&c=2U2bQ71RGLbZ-kcNH0VZGz_T_9JTa4T2PuFsrrlOkhg=');
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  width: 100%;
  text-align: center;
}

.splash h1 {
  font-size: 58px;
  font-family: "Dancing Script", serif;
  animation: textload 1s ease-in-out;
}

.splash p {
  margin-bottom: 20vh;
  padding: 5px;
  animation: textload 1.2s ease-in-out;
}

.splash div {
  animation: textload 1.4s ease-in-out;
}

@keyframes textload {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
