
* {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 770px) {
  body {
    display: flex;
    justify-content: center;
    min-height: 100vh;
    background-color: rgba(241, 241, 241, 0.941);
  }

  .AddHouse-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 85%;
    width: 80vh;
    padding: 10px;
    border-radius: 10px;
    background-color: lightgray;
  }

  .AddHouse-container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    margin-top: 1vh;
  }

  .AddHouse-container form input {
    width: 100%;
    margin-bottom: 1vh;
    outline: none;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }

  .AddHouse-container form textarea {
    margin-bottom: 1vh;
    outline: none;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: none;
  }

  .AddHouse-container form button {
    cursor: pointer;
    height: 4vh;
    width: 30vh;
    margin-top: 1vh;
    border-radius: 5px;
    border: none;
    background-color: #020245;
    color: white;
  }
}

.houseUrl {
  height: 20vh;
  width: 20vh;
}
