/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS (and SCSS, if configured) file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree .
 *= require_self
 */

@import url('https://fonts.googleapis.com/css2?family=Figtree&family=Fjalla+One&family=Pacifico&family=Roboto&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

a {
  text-decoration: none;
  color: #000;
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

/* Reservations */

.reservations-list-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reservations-list-page h2 {
  text-align: center;
}

.reservation-page,
.home-page,
.add-house-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: #000;
}

.reservation-page,
.add-house-page {
  text-align: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(6, 5, 26, 0.5)), url('https://plus.unsplash.com/premium_photo-1661883964999-c1bcb57a7357?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=828&q=80');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

.reservation-page {
  padding: 0%;
  height: 100vh;
}

.main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 10px;
  padding: 5px;
  width: 100%;
  color: #5f5f5f;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.homepage-container {
  display: grid;
  grid-template-rows: 70% 30%;
  gap: 15px;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.icon {
  width: 120px;
  height: 140px;
  border-radius: 45%;
}

.container p {
  text-align: left;
  font-size: 10px;
  color: #5f5f5f;
}

@media screen and (min-width: 768px) {
  .main-container {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .container {
    align-items: center;
  }
}
